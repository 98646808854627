<template>
  <div>
    <div class="box mp10">
      <div class="title">房屋照片</div>
      <div class="content">
        <div class="imgTitle">正大门照片（{{ mainGate.length }}/10）</div>
        <div class="imgbox">
          <div>正大门照片：</div>
          <div style="position: relative">
            <uploadImg
              @emitUpload="emitUpload"
              :list="this.mainGate"
              :text="'房屋内部照片'"
              :type="'main_gate'"
              :previewImgList="this.mainGate"
              :disabled="true"
            >
            </uploadImg>
          </div>
          <div>暂无信息</div>
        </div>
      </div>
      <div class="content">
        <div class="imgTitle">
          主房房屋全景照片（可看清房屋层数）{{ fullView.length }}/10）
        </div>
        <div class="imgbox">
          <div>房屋全景照片：</div>
          <div style="position: relative">
            <uploadImg
              @emitUpload="emitUpload"
              :list="this.fullView"
              :text="'房屋内部照片'"
              :type="'full_view'"
              :previewImgList="this.fullView"
               :disabled="true"
            >
            </uploadImg>
          </div>
          <div>暂无信息</div>
        </div>
      </div>

      <div class="content">
        <div class="imgTitle">屋顶照片：{{ mainRoof.length }}/10）</div>
        <div class="imgbox">
          <div>屋顶照片：</div>
          <div style="position: relative">
            <uploadImg
              @emitUpload="emitUpload"
              :list="this.mainRoof"
              :text="'屋顶照片：'"
              :type="'main_roof'"
              :previewImgList="this.mainRoof"
               :disabled="true"
            >
            </uploadImg>
          </div>
          <div>暂无信息</div>
        </div>
      </div>
    </div>

    <div class="box mp10">
      <div class="title">其他照片信息</div>
      <div class="content">
        <div class="imgbox">
          <div>奥维定位照片：</div>
          <div style="position: relative">
            <uploadImg
              @emitUpload="emitUpload"
              :list="this.azimuth"
              :text="'奥维定位照片：'"
              :type="'azimuth'"
              :previewImgList="this.azimuth"
              :disabled="true"
            >
            </uploadImg>
          </div>
          <div>暂无信息</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadImg from "./uploadImgList.vue";
export default {
  components: { uploadImg },
  computed: {
    mainGate() {
      return this.$store.state.expoleManageDetail.mainGate.map(
        (item) => item.explorateImgUrl
      );
    },
    fullView() {
      return this.$store.state.expoleManageDetail.fullView.map(
        (item) => item.explorateImgUrl
      );
    },
    mainRoof() {
      return this.$store.state.expoleManageDetail.mainRoof.map(
        (item) => item.explorateImgUrl
      );
    },
    azimuth(){
      return this.$store.state.expoleManageDetail.azimuth.map(
        (item) => item.explorateImgUrl
      );
    }
  },
  methods: {
    emitUpload() {},
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;

  background-color: #fff;
  font-size: 14px;
  border-radius: 5px;
  color: #333;

  .title {
    border-bottom: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;

    &::before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 20px;
      margin-right: 15px;
      background-color: #02a7f0;
      // 和标题对其
      vertical-align: middle;
    }
  }

  .content2 {
    padding: 0px 20px 20px 20px;
    box-sizing: border-box;
  }

  .content {
    padding: 0px 20px;
    box-sizing: border-box;

    .imgTitle {
      margin-top: 10px;
      box-sizing: border-box;
      font-weight: 700;
      padding-bottom: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #eee;
    }

    .imgbox {
      padding: 10px;
      box-sizing: border-box;
    }

    .fa {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding-left: 15px;
      box-sizing: border-box;
      background-color: #e3f5fd;
      margin: 10px;
    }
  }
}
</style>
