import { render, staticRenderFns } from "./houses.vue?vue&type=template&id=3ff3ef09&scoped=true"
import script from "./houses.vue?vue&type=script&lang=js"
export * from "./houses.vue?vue&type=script&lang=js"
import style0 from "./houses.vue?vue&type=style&index=0&id=3ff3ef09&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ff3ef09",
  null
  
)

component.options.__file = "houses.vue"
export default component.exports