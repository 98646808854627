import { render, staticRenderFns } from "./uploadImgObj.vue?vue&type=template&id=1d8664a8&scoped=true"
import script from "./uploadImgObj.vue?vue&type=script&lang=js"
export * from "./uploadImgObj.vue?vue&type=script&lang=js"
import style0 from "./uploadImgObj.vue?vue&type=style&index=0&id=1d8664a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d8664a8",
  null
  
)

component.options.__file = "uploadImgObj.vue"
export default component.exports